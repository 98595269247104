<ion-content>
  <div class="wide-viewport-container">
    <div
      class="instruction-text margin-top-48 margin-bottom-48 font-color-primary"
    >
    </div>
    <h1 class="text-align-center">Welcome to {{ env?.appDescription }}</h1>
    <div class="headline-text">
      VaxTrac Contractor Registration
    </div>
    <!-- <div class="instruction-text">
      The education and social engagement platform that providers and
      diagnosticians trust.
    </div> -->
  </div>
  <!-- <div class="shaded">
    <div class="wide-viewport-container">
      <h4 style="margin-top: 0; margin-bottom: 48px">
        How does {{ env?.appDescription }} work?
      </h4>

      <div class="visual-step">
        <div class="visual-step-image step1"></div>
        <div class="visual-step-text">
          <span>
            <strong>STEP 1:</strong><br />
            Yadda
          </span>
        </div>
      </div>

      <div class="visual-step">
        <div class="visual-step-image step2"></div>
        <div class="visual-step-text">
          <span>
            <strong>STEP 2:</strong><br />
            Yadda
          </span>
        </div>
      </div>

      <div class="visual-step">
        <div class="visual-step-image step3"></div>
        <div class="visual-step-text">
          <span>
            <strong>STEP 3:</strong><br />
            Yadda
          </span>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="wide-viewport-container">
    <h4 style="margin-top: 24px">
      Will I be reprimanded for sharing an incident?
    </h4>
    <div class="instruction-text">
      Yadda
    </div>

    <h4>Can I share anonymously?</h4>
    <div class="instruction-text">
      Yadda
    </div>

    <h4>What happens if my case is shared with my peers?</h4>
    <div class="instruction-text">
      Yadda
    </div>

    <h4>Can I review other peer cases?</h4>
    <div class="instruction-text">
      Yadda
    </div>
    <div class="button-footer">
      <ion-button class="standard-cta" (click)="dismiss()"
        >Get Started</ion-button
      >
    </div>
  </div> -->
</ion-content>
