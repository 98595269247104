import { User } from 'src/app/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AuthState } from 'src/app/models/_core/auth-state';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  env = environment;

  constructor(
    private http: HttpClient,

  ) { }

  getAll(activeOnly: boolean): Observable<User[]> {
    const activeOnlyParam = activeOnly ? '?activeOnly=1' : '';
    const url = `${this.env.apiUrl}/getUsersAll${activeOnlyParam}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getById(recordId): Observable<any> {
    const url = `${this.env.apiUrl}/getById`;
    const body = {
      id: recordId
    };

    return this.http.post(url, body).pipe(
      map((data: any) => {
        console.log('getbyid', data);
        return data;
      })
    );
  }

  getUserAuthType(recordId): Observable<User> {
    const url = `${this.env.apiUrl}/getUserAuthType`;
    const body = {
      id: recordId
    };

    return this.http.post(url, body).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  create(user: User) {
    const url = `${this.env.apiUrl}/addUser`;
    //user.groupId = 0;
    user.isLoggingOn = 0;
    return this.http.post(url, user).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  update(user: User): Observable<any> {
    const url = `${this.env.apiUrl}/saveNEI`;
    return this.http.post(url, user).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
