<ion-app>
  <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>
</ion-app>
<div class="loader-backdrop" [ngClass]="{ show: isLoading }">
  <div class="spinner-wrapper">
    <div class="spinner-background">
      <ion-spinner></ion-spinner>
      <div class="loading-message" *ngIf="loadingMessage">{{ loadingMessage }}</div>
    </div>
  </div>
</div>
