<ion-content>
  <ion-list lines="none">
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('my-account')">
      <ion-label>
        <h2>My Account</h2>
      </ion-label>
    </ion-item>
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('help')">
      <ion-label>
        <h2>Get Help</h2>
      </ion-label>
    </ion-item>
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('about')">
      <ion-label>
        <h2>About {{ env.appDescription }}</h2>
      </ion-label>
    </ion-item>
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('feedback')">
      <ion-label>
        <h2>Give feedback</h2>
      </ion-label>
    </ion-item>
    <!-- <ion-item ion-button href='javascript:void(0)' (click)="openPage('share')">
      <ion-label>
        <h2>Share this app</h2>
      </ion-label>
    </ion-item> -->
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('logout')">
      <ion-label>
        <h2>Sign out</h2>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
