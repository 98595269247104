import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';
import { Component, HostListener, OnInit } from '@angular/core';
import { BhAnalyticsService } from './services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from './services/_core/notifications/notifications.service';
import { UserDeviceService } from './services/_core/user-device/user-device.service';
import { VerlockerService } from './services/_core/verlocker/verlocker.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { AuthService } from './services/_core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ThemeOption } from './models/_core/theme-option';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
/**
 * ID: app-component
 * Name: App-Component
 * Description: Root App Component that handles root-level app-wide logic.
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2022-05-26 - MW - v2: Integraeted dark mode preference
 */
export class AppComponent implements OnInit {
  env = environment;
  prefersDark = false;
  theme: ThemeOption = 'M';
  subs: Subscription[] = [];
  updateInterval = null;
  displayingVerlocker = false;
  loadingSub: Subscription = null;
  isLoading = false;
  loadingMessage = '';

  constructor(
    private deviceService: UserDeviceService,
    private platform: Platform,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private verlockerService: VerlockerService,
    private authService: AuthService
  ) {
    this.initializeApp();
    this.subscribeToLoader();
    //this.checkVersion();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.deviceService.loadDeviceProperties();
  }

  async ngOnInit() {
    this.listenForThemePreference();
    this.subscribeToUserDevice();
    this.subscribeToUserState();
  }


  listenForThemePreference() {
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (mediaQuery) => {
        const device = this.deviceService.deviceSubject.getValue();
        device.prefersDark = mediaQuery.matches;
        this.deviceService.deviceSubject.next(device);
      });
  }

  subscribeToUserState() {
    this.subs.push(
      this.authService.userStateSubject.subscribe(us => {
        console.log('UserState updated', us);
        this.theme = us.theme;
        this.setTheme();
      })
    );
  }

  subscribeToUserDevice() {
    this.subs.push(
      this.deviceService.deviceSubject.subscribe(d => {
        console.log('UserDevice updated', d);
        this.prefersDark = d.prefersDark;
        this.setTheme();
      })
    );
  }

  setTheme() {
    // Check if theme is user-defined
    let currentTheme: ThemeOption;
    if (this.env.theme === 'user') {
      if (this.theme !== 'M') {
        switch (this.theme) {
          case 'L':
            document.body.classList.remove('dark');
            currentTheme = 'L';
            break;
          case 'D':
            document.body.classList.add('dark');
            currentTheme = 'D';
            break;
        }
      } else if (this.prefersDark) {
        document.body.classList.add('dark');
        currentTheme = 'D';
      } else {
        document.body.classList.remove('dark');
        currentTheme = 'L';
      }
    } else {
      // Theme is defined by environment
      if (this.env.theme === 'dark') {
        document.body.classList.add('dark');
        currentTheme = 'D';
      } else {
        document.body.classList.remove('dark');
        currentTheme = 'L';
      }
    }
    this.authService.setTheme(currentTheme);
  }

  async initializeApp() {
    // await this.storage.create();
    this.platform.ready().then(() => {
      this.analytics.initAnalytics();
      this.initNativeFeatures();
    });
  }

  async initNativeFeatures() {
    if (this.platform.is('mobile')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      // this.statusBar.styleDefault();
      await StatusBar.hide();
    }
  }

  subscribeToLoader() {
    this.loadingSub = this.notifications.isLoadingBehaviorSubject.subscribe(val => {
      this.isLoading = val.isLoading;
      this.loadingMessage = val.message;
    });
  }

  checkVersion() {
    if (!this.updateInterval) {
      firstValueFrom(this.verlockerService.checkVersion())
      this.updateInterval = setInterval(() => {
        if (!this.verlockerService.displayingVerlocker) {
          firstValueFrom(this.verlockerService.checkVersion());
        } else {
        }
      }, 120000);
    }
  }
}
